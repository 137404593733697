require('./bootstrap');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

//require('./particles.min.js');
//require('./my_particles.js');
